body {
    background-color: var(--color-background-edges)
}
#root > .container {
    background-color: var(--color-background-1);
    color: var(--color-text-on-background-1);
}
.home-body {
    text-align: center;
    width: 100%;
    margin-bottom: 100px;
}
.home-body-inner {
    width: 100%;
    display: inline-block;
    text-align: left;
}
.large-blurb-text {
    width: 100%;
    font-size: 36px;
    font-weight: 800;
    line-height: 1.1;
    margin: 50px 0 50px 0;
    text-align: center;
}
.medium-blurb-container {
    font-weight: 500;
    width: 100%;
    text-align: center;
    vertical-align: middle;
}
.medium-blurb-container, .medium-blurb-container a {
    background-color: var(--color-active-1);
    color: var(--color-text-on-active-1);
}
.medium-blurb-container > div {
    padding-left: 80px;
    padding-right: 80px;
}
.medium-blurb-container-inner-left {
    display: inline-block;
}
.medium-blurb-container-inner-right {
    display: inline-block;
    padding: 25px 0;
    vertical-align: top;
}
.medium-blurb-container-inner-left > div {
    font-size: 24px;
    font-weight: 800;
    padding: 40px 0 20px 0;
}
.medium-blurb-container-inner-left > ul {
    text-align: left;
    font-size: 16px;
    padding-bottom: 40px;
    margin: 0;
}
.medium-blurb-container-inner-right > a {
    padding: 12px 60px;
    border: 5px solid var(--color-text-on-active-1);
    font-weight: 900;
    text-decoration: none;
    text-shadow: none;
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
}
.medium-blurb-container-inner-right > a:hover, .method-blurb:hover {
    border-color: var(--color-hover-1);
    color: var(--color-hover-1);
    cursor: pointer;
    text-decoration: none;
}
div.medium-blurb-container-inner-left > ul > li {
    list-style-type: none;
}
div.medium-blurb-container-inner-left > ul > li:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f2e5";
    margin: 0 15px 0 -22px;
}
.method-blurb-container {
    padding: 60px;
    height: 320px;
    display: flex;
    text-align: justify;
}
.method-blurb {
    display: inline-block;
    margin-bottom: 50px;
    padding: 32px 10px;
    text-align: center;
    color: var(--color-inactive-1);
    border: 5px solid;
    height: 250px;
    width: 260px;
    text-decoration: none;
    background-color: transparent;
}
.method-blurb-container > .stretch {
    width: 10%;
}
.method-blurb > i {
    font-size: 72px;
}
.method-blurb-name {
    font-size: 28px;
    font-weight: 900;
}
.method-blurb-description {
    margin-top: 16px;
    font-size: 14px;
}
.info-page-inner {
    width: 450px;
    text-align: left;
    display: inline-block;
    margin: 0px 0 50px 0;
}
.info-page-inner > h1 {
    color: var(--color-inactive-1);
    font-size: 26px;
    font-weight: 900;
    margin-top: 40px;
}
.info-page-inner > b > i.fa-utensil-spoon {
    color: var(--color-active-1);
}
.info-page-inner.wide {
    width:700px;
    list-style-position:inside;
}
.contact-page-inner {
    width: 800px;
    display: inline-block;
}
ul.bullet-spoons > li {
    list-style: none;
}
ul.bullet-spoons > li:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f2e5";
    margin: 0 10px 0 -25px;
    color: var(--color-active-1);
}
a {
    color: var(--color-active-1);
}
a:hover, a:focus {
    color: var(--color-hover-1);
}
.btn-close {
    background: var(--color-x);
}
@media (min-width: 767px) {
    .container-md, .container-sm, .container {
        max-width: 750px;
    }
}
@media (min-width: 992px) {
    .container-md, .container-sm, .container {
        max-width: 970px;
    }
}
@media (min-width: 1200px) {
    .container-md, .container-sm, .container {
        max-width: 1170px;
    }
}
@media (max-width: 767px) {
    div > .info-page-inner, div > .contact-page-inner {
        width: 90%;
    }
    .contact-page-inner > .method-blurb-container {
        height: 100%;
    }
    .info-page-inner.wide {
        width: 100%;
        list-style-position: inside;
    }
    .double-control-container > .stretch {
        display: inline-block !important;
        width: 1px;
        margin: 0;
    }
}
