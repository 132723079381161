@media (max-width: 767px) {
  .stir-menu > div > button.fourths {
    font-size: 0.75em;
  }
}

.fourths {
  border: 0;
  padding: 8px 0 8px 0;
  width: 25%;
  background-color: var(--color-active-1);
  text-align: center;
  font-size: 1em;
  text-shadow: none;
  font-weight: 900;
  vertical-align: middle;
  color: var(--color-text-on-active-1);
  cursor: pointer;
}
.fourths.current {
    background-color: var(--color-hover-1);
}
.fourths.current:hover {
  cursor: default;
  color: var(--color-text-on-active-1);
}
.fourths:hover {
  color: var(--color-hover-1);
}
.stir-menu {
  width: 750px;
  margin: 0 -15px 0 -15px;
  display: inline-flex;
  text-align: left;
}
.stir-menu > div {
  padding: 0;
  display: contents;
}
.stir-menu > div > ul {
  display: contents;
}
.arrow-bar-left {
  clip-path: polygon(0% 0%, 93% 0%, 100% 50%, 93% 100%, 0% 100%);
}
.arrow-bar-middle {
  clip-path: polygon(0% 0%, 93% 0%, 100% 50%, 93% 100%, 0% 100%, 7% 50%);
}
.arrow-bar-right {
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 7% 50%);
}