.no-content-outer {
    width: 100%;
    color: var(--color-inactive-1);
    margin-top: 80px;
    margin-bottom: 80px;
    display: inline-flex;
    text-align: center;
    font-weight: 900;
    font-size: 32px;
}
.no-content-inner {
    width: 100%;
    display: inline;
}
.no-content-inner > div, .no-content-inner > img {
    margin-bottom: 40px;
}
.no-content-inner > img {
    max-height: 150px;
}
.no-content-outer.half {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 24px;
}
.no-content-inner > p {
    margin-top: 10px;
    margin-bottom: 10px;
}
.no-content-description {
    margin-top: 20px;
    padding: 0 4%;
    font-size: 14px;
}
@media (max-width: 767px) {
    .tile-dual-inner > .no-content-outer.half {
        margin-top: 10px;
    }
}
