@media (max-width: 767px) {
    .tile-outer.tilehead-outer {
        max-height: inherit !important;
    }
    .tilehead {
        max-height: inherit !important;
    }
    .tilehead > .tilehead-top {
        font-size: 20px;
        margin-bottom: 4px;
    }
    .tilehead > .tilehead-bottom {
        display: block;
        font-size: 11px;
        max-height: inherit;
    }
    .tilehead > .tilehead-bottom > div {
        min-width: 100%;
        max-height: inherit;
        width: inherit;
        height: 100%;
        padding: 0;
    }
    .tilehead > .tilehead-bottom > div, .tilehead > .tilehead-bottom > div > div {
        margin-bottom: 6px;
    }
    .tilehead > .tilehead-bottom > .tilehead-oneliner:last-child {
        text-align: left;
    }
    .tilehead > .tilehead-bottom > .tilehead-oneliner > div:last-child {
        margin-bottom: 0;
    }
}

.tile-outer.tilehead-outer {
    max-height: 13em;
    height: 100%;
    margin-top: 15px !important;
    border: solid var(--color-inactive-1) 2px;
    padding: 8px 8px 0 8px;
}
.tile-container > .tile-dropdown.dropdown > .tile-outer.tilehead-outer {
    border-width: 2px;
}
.tilehead {
    overflow: hidden;
    max-height: 132px;
    height: auto;
    display: block;
    margin-bottom: 12px;
}
.tilehead-top {
    font-size: 28px;
    font-weight: 900;
    margin-bottom: 2px;
    color: var(--color-background-edges);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.tilehead-bottom {
    display: flex;
    font-size: 12px;
    max-height: 8em;
    justify-content: space-between;
    width: 100%;
}
.tilehead-bottom > :first-child {
    padding-left: 0px;
    text-align: left !important;
}
.tilehead-bottom > :last-child {
    padding-right: 0px;
}
.tilehead-bottom > .tilehead-oneliner:last-child {
    text-align: right;
}
.tilehead-oneliner > div > span > i.fa-trophy {
    margin-left: -2px;
    margin-right: 6px;
}
.tilehead-bottom > div {
    min-width: 20%;
    max-height: inherit;
    position: relative;
    text-overflow: ellipsis;
    white-space: normal;
    line-height: normal;
    overflow: hidden;
    height: 100%;
    padding: 0 1%;
    flex: 1 1 auto;
}
.tilehead-bottom > div > label {
    cursor: pointer;
    margin: 0;
}
.tilehead-bottom > div > img {
    top: -2.5em;
    right: 1.0em;
    max-width: 10em;
    max-height: 10em;
    position: absolute;
}
.tilehead-outer > div > .tile-bottom {
    font-weight: 900;
}
.tilehead-bottom > .tilehead-double-column {
    min-width: 30%;
    display: flex;
}
.tilehead-bottom > .tilehead-double-column > div {
    margin-right: 1em;
}
.tilehead-bottom > .tilehead-double-column > div > div {
    display: flex;
}
.tilehead > .tilehead-bottom > .tilehead-oneliner {
    min-width: 30%;
}
