.modal-title {
    font-weight: 900;
    display: inline;
}
.modal-backdrop {
    opacity: 0.7;
}
.modal-footer > .btn-default:hover {
    color: var(--color-text-hover-2);
}
.modal-footer > .btn-default.secondary {
    background-color: var(--color-inactive-1);
}
.modal-footer > .btn.btn-default {
    margin-left: 15px;
}
.modal-footer > button {
    width: 180px;
}
.modal-content {
    background-color: var(--color-background-1);
    color: var(--color-text-on-background-1);
}
.modal-body > div > label {
    margin-bottom: 2px;
}

@media (max-width: 767px) {
    .modal-footer > button {
        width: 150px;
    }
}
