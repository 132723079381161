@media (max-width: 767px) {
    body {
        padding: 0px;
    }
    .hide-when-narrow {
        display: none;
    }
    .stir-menu {
        width: 100% !important;
    }
    .stir-body-inner {
        width: 92%;
    }
    .stir-sub-menu > div {
        margin-right: 24px !important;
    }
    .large-blurb-text {
        font-size: 24px !important;
    }
    .medium-blurb-container > div {
        padding-left: 60px !important;
        padding-right: 60px !important;
    }
    .medium-blurb-container-inner-right {
        padding: 10px 0 50px 0 !important;
        display: block !important;
    }
    .method-blurb-container {
        display: block !important;
        padding: 20px 0 !important;
        text-align: center !important;
    }
    .medium-blurb-container-inner-right > a {
        padding: 12px 30px !important;
    }
    .medium-blurb-container-inner-left > ul {
        font-size: 14px !important;
    }
    .medium-blurb-container-inner-left > ul > li {
        margin-left: -40px;
        margin-right: -30px;
    }
    .method-blurb-container > .method-blurb {
        margin-left: 10%;
        margin-right: 10%;
    }
    .alert > .status-alert-button {
        color: var(--color-text-on-active-1);
        padding-top: 10px;
        font-size: 16px;
    }
    ul.dropdown-menu > li > a, ul.dropdown-menu > li > button {
        line-height: 2.5 !important;
    }
    .mobile-hide {
        display: none;
    }
}

@media (min-width: 767px) {
    .stir-body-inner {
        width: 720px !important;
    }
}

html {
    overflow-y: scroll;
}
body {
    font-family: 'Nunito', serif;
    background-color: var(--color-background-1);
}
.inline {
    display: inline !important;
}
.gold {
    color: goldenrod;
}
.silver {
    color: silver;
}
.bronze {
    color: #CD7F32;
}
.success {
    color: var(--color-success);
}
.red {
    color: var(--color-warning);
}
.disabled {
    color: var(--color-background-disabled) !important;
}
.validation-text {
    background-color: var(--color-background-disabled);
}
.validation-text > span {
    font-weight: 900;
    float: left;
    padding: 5px 10px;
}
.green-border {
    border-color: var(--color-success) !important;
}
.alert-info {
    background-color: var(--color-background-control);
}
.text, .alert-info {
    color: var(--color-text-control);
    border: none;
    border-radius: 0px;
}
.ginormous {
    font-size: 180px;
    margin-bottom: -60px !important;
}
.absolute {
    position: absolute;
}
.right {
    float: right;
}
.status-alert-icon {
    margin-right: 10px;
    font-size: 20px;
    vertical-align: bottom;
}
.status-alert-button {
    width: 35%;
    height: 48px;
    margin: -11px -20px 0 0;
    padding: 3px;
    text-align: center;
    font-weight: 900;
    font-size: 24px;
    display: inline;
    cursor: pointer;
    float: right;
    color: var(--color-background-1);
    background-color: var(--color-active-1);
}
.status-alert-button:hover {
    color: var(--color-hover-1);
    text-decoration: none;
}
.status-alert-button.disabled-button {
    background-color: var(--color-inactive-1);
}
.status-alert-button.waiting-button {
    cursor: wait
}
.status-alert-button.disabled-button:hover {
    text-decoration: none;
    color: var(--color-background-1);
}
.status-alert-button > .fa-spinner {
    margin-top: .4em;
}
.alert-dismissable > .close {
    right: -6px;
}
.alert-dismissible .btn-close {
    padding: .9rem 1rem;
}
.alert > i {
    padding-right: 1.75em;
    padding-left: 0.5em;
}
.center {
    text-align: center;
}
.left {
    text-align: left;
}
ul.dropdown-menu > li > a, ul.dropdown-menu > li > button {
    cursor: default;
    line-height: 2;
}
.italic {
    font-style: italic;
}
.bold {
    font-weight: 900;
}
.fa-question-circle {
    color: var(--color-border-3);
    width: 2em;
}
.fa-question-circle:hover {
    color: var(--color-hover-1);
}
.no-margin {
    margin: 0;
}
.tooltip {
    opacity: 1;
}
.tooltip-inner {
    text-align: left;
}
.allow-overflow {
    overflow: initial !important;
}
.active-color {
    color: var(--color-active-1);
}
.grid-footer {
    text-align: right;
    font-size: 1.5em;
    padding: 0 1em;
    width: 100%;
}
button {
    border: none;
    background: none;
}
.grid-footer > button {
    margin-right: 35px;
}
.add-button {
    border: none;
    color: var(--color-inactive-1);
    cursor: pointer;
}
.add-button:hover {
    color: var(--color-active-1);
}
input[type="checkbox"]:focus, a:focus, button:focus {
    outline-color: var(--color-hover-1);
    box-shadow: none;
}
input[type="checkbox"]:checked {
    background-color: var(--color-active-1);
    border-color: var(--color-active-1);
}
a.nav-link > div.fa-stack {
    height: 2.2em;
    width: 1.2em;
}
.grid-footer > a.nav-link {
    color: var(--color-inactive-1);
    cursor: pointer;
    display: inline;
}
.grid-footer > a.nav-link:hover {
    color: var(--color-active-1);
}
.grid-footer > a.nav-link.disabled-button {
    cursor: default;
}
.grid-footer > a.nav-link.disabled-button:hover {
    color: var(--color-inactive-1);
}
label {
    font-weight: 700;
}
.btn-primary {
    width: 100px;
    background-color: var(--color-active-1);
    color: var(--color-text-on-active-1);
    font-weight: 900;
    text-decoration: none;
    text-shadow: none;
    background-image: none;
    box-shadow: none;
    border: none;
    border-radius: 0;
}
.btn-primary:hover {
    background-color: var(--color-active-1);
    color: var(--color-hover-1);
}
.halo {
    text-shadow: 2px 2px 4px var(--color-hover-1), -2px -2px 4px var(--color-hover-1), 2px -2px 4px var(--color-hover-1), -2px 2px 4px var(--color-hover-1);
}
.flex-justify {
    display: flex;
    justify-content: space-between;
}
