.stir-bottom-menu {
    text-align: center;
    height: 150%;
    margin-top: 20px;
    margin-bottom: 40px;
    display: inline-flex;
}
.stir-bottom-menu > li {
    display: inline-block;
    width: 50%;
    height: 32px;
}
.stir-bottom-menu > li > a {
    padding-top: 5px;
}
.stir-bottom-menu > li > a, .stir-bottom-menu > li > button {
    border: 0;
    display: inline-block;
    width: 80%;
    height: 32px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    background-color: var(--color-active-1);
    text-decoration: none;
    color: var(--color-text-on-active-1);
    cursor: pointer;
}
.stir-bottom-menu > li > a.disabled, .stir-bottom-menu > li > button.disabled {
    background-color: var(--color-background-disabled);
    color: var(--color-text-on-active-1);
    cursor: default;
}
.stir-bottom-menu > li > a.disabled, .stir-bottom-menu > li > button.disabled:hover {
    color: var(--color-inactive-1);
}
.stir-bottom-menu > li > a:hover, .stir-bottom-menu > li > button:hover {
    color: var(--color-hover-1);
}

@media (max-width: 767px) {
    .stir-bottom-menu {
        width: 90%;
    }
}

@media (min-width: 767px) {
    .stir-bottom-menu {
        width: 720px;
    }
}
