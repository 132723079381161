.breadcrumb-container {
    font-weight: 900;
    font-size: 16px;
    cursor: default;
    width: 100%;
    text-align: left;
    display: inline-block;
    margin-top: 10px;
}
.breadcrumb-container > .breadcrumb {
    padding: 8px 8px 8px 0px;
}
.breadcrumb {
    color: var(--color-inactive-1);
    display: inline;
    background: none;
}
.breadcrumb > a {
    color: var(--color-active-1);
    text-decoration: none;
    cursor: pointer;
}
.breadcrumb > a:hover {
    color: var(--color-hover-1);
}
.breadcrumb > i {
    font-size: 12px;
}

@media (min-width: 767px) {
    .breadcrumb > i {
        color: var(--color-background-edges);
    }
}

@media (max-width: 767px) {
    .breadcrumb-container {
        font-size: 13px;
    }
    .breadcrumb-container > .breadcrumb {
        padding: 4px 4px 4px 0px;
    }
}
