.tile-outer.participant-assign {
    display: flex;
    height: 7.5em;
}
.participant-assign > .tile-inner {
    overflow: visible;
}
.participant-assign > div > .tile-bottom {
    width: auto;
    height: 4.5em;
    margin-right: 1em;
}
.tile-mini-container {
    width: 100%;
    background-color: var(--color-background-control);
}
.tile-mini-container.selected-participants {
    height: 100%;
    padding: .2em;
}
.tile-mini-container > .form-control {
    height: 100%;
}
.tile-mini {
    color: var(--color-active-1);
    background-color: var(--color-background-1);
    border-color: var(--color-border-3);
    border: 1px solid;
    border-radius: .25rem;
    display: inline-flex;
    line-height: 1.25em;
    margin: 2px 6px 2px 0px;
    height: fit-content;
}
.tile-mini > button {
    width: 0.25em;
    height: 0.25em;
    margin: 0.25em;
}
.rbt-close-content {
    visibility: hidden;
}
.tile-mini > div {
    margin: 0 .5em 0 1em;
}
.tile-mini.grabbable {
    cursor: grab;
}
.tile-mini.not-grabbable {
    cursor: default;
}
.tile-mini > div:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    margin: 0 0.25em 0 -0.75em;
}
.tile-mini.team > div:before {
    content: "\f0c8";
}
.tile-mini.participant > div:before {
    content: "\f007";
}
.rbt-input-multi {
    cursor: text;
}
.tile-mini-empty {
    height: 2em;
}
.selected-participants:empty {
    text-align: center;
    vertical-align: middle;
    height: 100%;
}
.select-participants-container {
    display: flex;
}
.select-participants-container > div {
    display: inline;
    width: 50%;
}
.select-participants-container .tile-mini {
    display: block;
    background-color: var(--color-background-tile);
}
.select-participants-header > div {
    display: inline-block;
    text-align: center;
    font-weight: 900;
    width: 50%;
}
.select-participants-container .tile-mini > div {
    padding: .25em;
}
