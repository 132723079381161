@media (max-width: 767px) {
    .half-stir-participant > .dropdown-menu {
        width: 220% !important;
    }
}

.stir-participant {
    width: 100%;
    display: flex;
}
.stir-participant > input, .stir-participant > div {
    margin: 10px;
}
.stir-participant > i {
    margin: 16px 6px 16px 6px;
    color: var(--color-inactive-1);
}
.half-stir-participant {
    width: 50%;
}
.full-stir-participant {
    width: 95%;
}
.full-stir-participant-header {
    width: 100%;
}
.half-stir-participant-header {
    width: 50%;
}
.stir-participant-header {
    color: var(--color-inactive-1);
    margin: 0 !important;
    text-align: center;
    font-weight: 900;
    height: 1.25em;
}
.half-stir-participant-header-narrow {
    width: 4.5em;
    margin-left: 0 !important;
    text-align: right;
}
.half-stir-participant-header-narrow > .fa-fw {
    width: 1.5em;
}
.fa-stack .fa-cog {
    font-size: xx-small;
    margin-top: -.5em;
    margin-left: 2.2em;
    width: .5em;
}
.stir-participant-type-dropdown {
    cursor: pointer;
    color: var(--color-inactive-1);
    width: 4.5em;
    text-align: center;
}
.stir-participant-type-blank {
    width: 2em;
}
.stir-participant-type-blank > i {
    margin: .5em 0;
}
.stir-participant-type-dropdown:hover {
    color: var(--color-hover-1);
}
.stir-participant-type-toggle {
    margin: .5em 0;
    font-size: 18px;
    text-align: right;
    padding-right: .5em;
}
.dropdown-item > .fa-stack {
    line-height: inherit;
    margin-top: 0;
}
.stir-participant-type-dropdown .dropdown-item {
    line-height: 2em;
}
.stir-participant-type-dropdown .fa-caret-down {
    position: absolute;
    margin: .25em -.4em;
}
.stir-participant-type-dropdown > div > div.fa-stack {
    margin-left: -1em;
    margin-top: inherit;
    margin-bottom: inherit;
}
.stir-participant-type-dropdown > div > div.fa-stack > .fa-cog {
    margin-left: 3.6em;
}
div.dropdown-menu > a.dropdown-item > * {
    color: var(--color-text-control);
}