.stir-container {
  margin: 8px -12px 0 -12px;
}
.stir-sub-menu {
  width: 100%;
  font-size: 15px;
  margin-top: 6px;
  padding: 8px 0 10px 0;
  display: inline-flex;
  min-height: 44px;
}
.stir-sub-menu > div {
  font-weight: 900;
  margin-right: 36px;
}
.stir-sub-menu > div:hover {
  cursor: pointer;
  display: inline;
  text-decoration: none;
  border-bottom: 4px solid var(--color-hover-1);
}
.stir-sub-menu > div.active {
  text-decoration: none;
  border-bottom: 6px solid var(--color-active-1);
}
.stir-step-top-content {
    margin-top: 20px;
}
.stir-checkbox {
    display: flex;
    padding: 10px 0 0 0;
}
.stir-checkbox.stir-checkbox-top-content {
    padding: 0 0 1.5em 0;
}
.stir-checkbox > div > .form-check-input {
  margin: 5px 10px 5px 0px;
}
.stir-checkbox > div.checkbox {
    margin-top: 0;
}
.padding-topbottom-10 {
    padding: 10px 0 10px 0;
}
.margin-top-10 {
    margin-top: 10px;
}
.padding-bottom-10 {
    padding: 0 0 10px 0;
}
.double-control-container {
    width: 100%;
    display: flex;
}
.stir-body {
    color: var(--color-text-on-background-1);
    text-align: center;
    width: 100%
}
.stir-body-inner {
    display: inline-block;
    text-align: left;
}
.fade-text {
    position: relative;
    height: 6.2em;
    overflow: hidden;
    text-overflow: ellipsis;
}
.fade-text:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 7%;
    min-width: 80px;
    height: 1.2em;
    background: linear-gradient(to right, transparent, var(--color-background-1) 50%);
}
.fade-text-more {
    background-color: transparent;
    right: 0px;
    bottom: -3px;
    z-index: 1;
}
.alert {
    margin: 10px 0px;
    padding: 10px 20px;
    text-shadow: none;
    box-shadow: none;
    background-image: none;
    text-align: left;
}
.stir-body > .alert {
    margin-top: 20px;
    margin-bottom: 0px;
}
body .form-select, body .form-control {
    font-weight: 900;
    color: var(--color-text-control);
    background-color: var(--color-background-control);
}
body .form-select:disabled, .form-select:disabled::placeholder {
    color: var(--color-text-disabled);
    background-color: var(--color-background-disabled);
    cursor: default;
}
.form-control::placeholder {
    color: var(--color-active-1);
}
.stir-body-inner > div > .react-datepicker-wrapper, .modal-body > div > .react-datepicker-wrapper {
    display: block;
}
div.react-datepicker {
    font-size: 1.1rem;
}
div.react-datepicker-time__header, div.react-datepicker__current-month {
    font-size: 1.2rem;
}
.stir-left-control {
    display: inline-block;
    width: 92%;
    background-color: var(--color-inactive-1);
}
.stir-icon-toggle {
    color: var(--color-inactive-1);
    font-size: 18px;
    margin-top: 4px;
    text-align: right;
}
.interactable.stir-icon-toggle {
    cursor: pointer;
}
.interactable.stir-icon-toggle:hover {
    color: var(--color-hover-1);
}
.stir-control-with-toggle {
    display: flex;
}
.stir-icon-toggle-label {
    margin: 0 1.25em;
}
.stir-creation-blurb {
    margin-left: .5em;
    margin-top: .75em;
    font-size: 13px;
}
.method-step-container {
    padding: 30px;
    border: solid var(--color-border-3) 1px;
    margin-bottom: 20px;
    font-size: 13px;
}
.method-step-container > .icon-bar {
    margin-top: -16px;
    float: right;
}
.method-step-container.collapsed {
    padding: 18px;
}
.method-step-container.collapsed, .method-step-container.collapsed > .method-step-matches-outer > .method-step-matches {
    cursor: pointer;
}
.method-step-container.collapsed:hover {
    border-color: var(--color-hover-1);
}
.method-step-summary-text {
    text-align: center;
}
.icon-bar > .stir-icon-toggle {
    margin-left: 24px;
}
.label-subtext {
    font-weight: 400;
    font-style: italic;
}
.control-label {
    margin-bottom: 3px;
    font-size: 12px;
}
.method-step-matches-outer {
    text-align: center;
}
.method-step-matches {
    color: var(--color-inactive-1);
    font-size: 24px;
    font-weight: 900;
    cursor: default;
}
.method-step-matches > .fa {
    margin-right: 8px;
}
.method-step-matches > .fa-stack {
    margin-top: -6px;
    width: 1em;
    height: 1em;
}
.method-step-matches > .fa-stack > .fa-stack-1x {
    width: 1em;
    line-height: normal;
}
.method-step-matches > .fa-stack > .fa-square {
    font-size: 26px;
    margin-top: -2px;
}
.method-step-matches > .fa-stack > .fa-user.fa-xs {
    font-size: 14px;
    margin-top: 5px;
    margin-left: 6px;
}
.method-step-matches > .number {
    margin-right: 3px;
}
.method-step-matches > .fa-stack > .fa-plus, .method-step-matches > .fa-long-arrow-alt-right {
    color: var(--color-border-3);
}
.method-step-matches > .fa-stack > .fa-plus {
    margin-top: 2px;
    margin-left: 3px;
}
.method-step-text-separator {
    color: var(--color-border-3);
    margin: 0 8px 0 8px;
}
.double-control-container > select, .double-control-container > input, .double-control-container > label {
    width: 50%;
}
.action-text {
    cursor: pointer;
    color: var(--color-active-1);
    font-weight: 900;
    background-color: initial;
    border-style: none;
    padding: 0;
}

@media (min-width: 767px) {
    .stir-control-with-toggle {
        width: 50%;
    }
    .stir-icon-toggle {
        margin-left: 15px;
        width: 0.8em;
    }
}

@media (max-width: 767px) {
    .stir-container {
        margin-top: 5px;
    }
    div > .stir-left-control {
        width: 85%;
    }
    .stir-icon-toggle {
        width: 10%;
        margin-left: 0px;
    }
    .stir-control-with-toggle {
        width: 100%;
    }
    .stir-control-with-toggle > * {
        display: inline;
    }
    .method-step-matches-outer > .method-step-matches {
        font-size: 18px;
    }
    .method-step-matches-outer > .method-step-matches > .fa-stack > .fa-square {
        font-size: 19px;
        margin-top: -2px;
    }
    .method-step-matches-outer > .method-step-matches > .fa-stack > .fa-user.fa-xs {
        font-size: 11px;
        margin-top: 3px;
        margin-left: 4px;
    }
    .method-step-matches > .fa {
        margin-right: 5px;
    }
    .tile-container > .alert {
        font-size: 11px;
        padding: 7px 14px;
    }
    .alert > .status-alert-icon {
        font-size: 16px;
    }
    .wide-mobile > .double-control-container {
        display: inline !important;
    }
    .wide-mobile > .double-control-container > select, .double-control-container > input {
        width: 100% !important;
    }
    .method-step-summary-text {
        font-size: 12px;
    }
}
