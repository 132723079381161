.tile-icon > .fa-stack {
  width: 1.25em !important;
  height: 1.25em !important;
}
.tile-icon > .fa-stack > .fa-stack-1x {
  width: 1em !important;
  line-height: normal;
}
.tile-icon > .fa-stack > .fa-square {
  margin-top: -7px !important;
  margin-left: -3px !important;
}
.tile-icon > .fa-stack > .fa-user.fa-xs {
  margin-left: 4px !important;
}
.tile-icon > .fa-stack > .fa-slash {
  margin-top: -3px;
  margin-left: -2px;
}
.tile-icon > .fa-stack > .fa-user-secret {
  margin-left: 0.2em;
}
.tile-icon > .fa-stack > .fa-cog {
    font-size: x-small;
    margin-left: 2.5em;
    margin-top: 0em;
}
.tile-container {
  width: 100%;
  text-align: center;
  display: inline-block;
  margin-bottom: 50px;
  cursor: default;
}
.tile-dropdown {
  width: 100%;
}
.tile-outer {
  height: 50px;
  width: 100%;
  color: var(--color-text-control);
  margin-top: 8px;
  margin-bottom: 8px;
  border: solid var(--color-inactive-1) 1px;
  background-color: var(--color-background-tile);
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: inline-flex;
}
.dropdown-menu {
  color: var(--color-text-control);
  background-color: var(--color-background-control);
}
.dropdown-menu .dropdown-header {
  font-weight: 900;
  color: var(--color-text-disabled);
  cursor: default;
}
.tile-container > .tile-dropdown.dropdown > .tile-outer, .tile-dual-inner > .tile-dropdown > .tile-outer {
  cursor: pointer;
  border: solid var(--color-active-1) 1px;
}
.tile-container > .tile-dropdown.dropdown > .tile-outer:hover, .tile-dual-inner > .tile-dropdown > .tile-outer:hover {
  border-color: var(--color-hover-1);
}
.tile-icon {
  font-size: 24px;
}
.tile-inner {
  width: calc(100% - 10px);
  text-align: left;
  padding-left: 10px;
  margin-top: -3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: grid;
}
.tile-top {
  width: 100%;
  display: flex;
  font-size: 16px;
  font-weight: 900;
  margin-bottom: -8px;
}
.no-info > .tile-inner > .tile-top {
  font-size: 16px;
  font-weight: 900;
  margin-top: 6px;
  margin-bottom: -10px;
}
.no-info > .tile-inner > .tile-bottom > .tile-bottom-right {
  margin-top: -4px;
}
.tile-top-left {
  color: var(--color-background-edges);
  display: inline;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tile-top-right {
  font-size: 10px;
  display: inline;
  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.tile-bottom {
  width: 100%;
  display: inline-flex;
}
.tile-bottom-left {
  font-size: 11px;
  display: contents;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tile-bottom-left > .glyphicon {
  margin-right: 4px;
}
.tile-bottom-right {
  font-size: 14px;
  display: inline;
  width: inherit;
  margin-top: -4px;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tile-bottom-right > div {
  display: inline-block;
  margin-left: 6px;
}
.tile-dual-outer {
  display: block;
  text-align: justify;
  text-align: center;
  min-height: 100px;
}
.tile-dual-inner {
  display: inline-block;
  max-width: 48%;
  vertical-align: top;
}
.tile-dual-inner-header {
  margin-top: 10px;
  text-align: left;
  font-weight: 900;
  color: var(--color-inactive-1);
}
.tile-inline-icon {
  font-size: 12px;
  margin-left: 5px;
  vertical-align: top;
}
.dropdown > i {
  cursor: pointer;
}
.small-stack {
  display: inline-flex;
  margin-top: -4px;
}
.dropdown-menu-right {
  right: 0 !important;
  left: auto !important;
}
div.dropdown-menu > .dropdown-item {
  color: var(--color-text-disabled);
  background-color: var(--color-background-control);
  text-align: left;
  width: 100%;
  border-style: none;
  display: block;
  padding: 6px 20px;
  cursor: pointer;
  text-decoration: none;
}
div.dropdown-menu > .dropdown-item:hover {
  color: var(--color-text-disabled);
  background-color: var(--color-background-tile);
  background-image: none;
  text-decoration: none;
}

.sortable-container {
    padding-bottom: 50px; /*leave room to drag items into last place*/
}

.sortable-container > div > .tile-icon > .fa-bars:hover {
  color: var(--color-hover-1);
  cursor: grab;
}
.tile-top-right > .fa-times {
  font-size: 18px;
  margin-top: -6px;
  margin-right: -4px;
}
.tile-icon > .fa-plus, .tile-icon > .fa-minus, .tile-icon > .fa-bars {
    color: var(--color-background-edges);
}
.tile-icon > .fa-plus:hover, .tile-icon > .fa-minus:hover, .tile-top-right > .fa-times:hover {
  color: var(--color-hover-1);
  cursor: pointer;
}
.tile-bottom-left > .fa-fw {
  margin: 0 4px 0 0;
}
.tile-bottom-left > .main-text {
  font-weight: 600;
}
.tile-bottom-left > .note-text {
  font-style: italic;
}
.fa-stack > .fa-question {
  margin-left: 5.5px;
  margin-top: 3.5px;
  font-size: 14px;
}
.tile-icon > .fa-stack > .fa-star {
  font-size: 10px;
}
.tile-icon > .fa-stack > .fa-star.elect-star-1of1 {
  font-size: 12px;
  margin-left: 6.5px;
  margin-top: 4px;
}
.tile-icon > .fa-stack > .elect-star-1of2 {
  margin-left: 2.5px;
  margin-top: 0px;
}
.tile-icon > .fa-stack > .elect-star-2of2 {
  margin-left: 12.5px;
  margin-top: 9px;
}
.tile-icon > .fa-stack > .elect-star-1of3 {
  margin-left: 7.5px;
  margin-top: 0px;
}
.tile-icon > .fa-stack > .elect-star-2of3 {
  margin-left: 1px;
  margin-top: 9px;
}
.tile-icon > .fa-stack > .elect-star-3of3 {
  margin-left: 14px;
  margin-top: 9px;
}
.fa-inverse.fa-user, .fa-inverse.fa-user-secret {
  color: var(--color-background-1);
}
.tile-container > .grid-footer {
  margin-top: 12px;
}
.tile-container > .grid-footer > a > .add-button {
  margin-right: 0px;
}

@media (max-width: 767px) {
  .tile-bottom-left {
    font-size: 11px !important;
    width: 60% !important;
  }
  .tile-bottom-right {
    font-size: 12px !important;
    margin-top: 2px !important;
  }
  .tile-outer > .tile-inner {
    margin-top: -1px;
    width: 94%;
  }
  .tile-outer.tilehead-outer > .tile-inner {
    width: 100%;
  }
  .tile-dual-inner {
    min-width: 100%;
  }
  .tile-top {
    font-size: 14px !important;
    margin-bottom: -6px !important;
  }
  .tile-icon {
    width: 12%;
    max-width: 40px;
  }
  .no-info > .tile-inner > .tile-bottom > .tile-bottom-right {
    margin-top: 0px !important;
  }
  .fa-stack > .fa-question {
    margin-left: .3em;
    margin-top: .3em;
    font-size: .6em;
  }
}

@media (min-width: 767px) {
  .stretch {
    height: 0px;
    max-height: 0;
    display: inline-block;
    width: 4%;
  }
  .tile-dual-inner {
    min-width: 48%;
  }
  .tile-container {
    width: 720px !important;
  }
}
