@media (max-width: 767px) {
  .nav-menu > .navbar-brand {
    margin-top: -6px;
    padding-left: 18px;
    font-size: 24px;
  }
}
.navbar-nav {
  margin: 0;
  display: flex;
  float: right;
}
.navbar-right {
  padding-right: 15px;
  margin-top: -8px;
}
.nav-link {
  padding: 15px 15px;
  font-size: 14px;
}
.nav-menu {
  margin: 0 -12px 0 -12px;
  background-image: none;
  text-decoration: none;
  box-shadow: none;
  text-shadow: none;
  border: none;
  border-radius: 0;
  background-color: var(--color-active-1);
  min-height: 50px;
  display: block;
}
.navbar-brand {
  margin-top: -10px;
  padding-left: 40px;
  font-size: 28px;
  font-weight: 900;
  display: flex;
  float: left;
  color: var(--color-text-on-active-1) !important;
}
a.navbar-brand:hover, .navbar-nav > a:hover {
  color: var(--color-hover-1) !important;
}
.navbar > .nav-link, .navbar-nav > .nav-link {
  color: var(--color-text-on-active-1);
  text-shadow: none;
  font-weight: 600;
  text-decoration: none;
  margin-right: 12px;
}
.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus, .navbar-default .navbar-nav > .active > a {
  background-image: none;
  box-shadow: none;
  background-color: transparent;
}
